<template>
    <div class="age">
        <Crumbs></Crumbs>
        <el-row class="age-top">
            <div class="age-top-left"></div>
            <div class="age-top-right">
                <el-button class="age-top-btn" size="small" type="primary" @click="downloadExcel">导出</el-button>
            </div>
        </el-row>
        <!-- 表格 -->
        <el-row class="age-cen" style="margin-top: 10px;padding: 10px;">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column align="center" label="计数项:司龄(年)">
                    <el-table-column align="center" prop="deptName" label="部门" />
                </el-table-column>
                <el-table-column align="center" label="计数(人)">
                    <el-table-column align="center" prop="zero_to_one" label="0-1年" />
                </el-table-column>
                <el-table-column align="center" label="">
                    <el-table-column align="center" prop="one_to_three" label="1-3年" />
                </el-table-column>
                <el-table-column align="center" label="">
                    <el-table-column align="center" prop="three_to_five" label="3-5年" />
                </el-table-column>
                <el-table-column align="center" label="">
                    <el-table-column align="center" prop="more_than_five" label="5年以上" />
                </el-table-column>
                <el-table-column align="center" label="">
                    <el-table-column align="center" prop="departTotal" label="总计" />
                </el-table-column>
            </el-table>
        </el-row>
        <!-- 柱形图 -->
        <el-row style="padding: 10px;">
            <div id="siLing" style="width: 99%;height:500px;"></div>
        </el-row>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            tableData: [],
        }
    },
    created() {
        this.getData();
    },
    mounted() {

    },
    methods: {
        getData() {
            this.$api.user.staffWorkAgeReport().then(res => {
                let temp = {};
                for (let i in res.data) {
                    if (i == '总计') {
                        temp = {
                            deptName: '总计',
                            zero_to_one: res.data[i].zero_to_one_total,
                            one_to_three: res.data[i].one_to_three_total,
                            three_to_five: res.data[i].three_to_five_total,
                            more_than_five: res.data[i].more_than_five_total,
                            departTotal: res.data[i].total,

                        };
                    } else {
                        let o = {};
                        o.deptName = i;
                        o.zero_to_one = res.data[i].zero_to_one;
                        o.one_to_three = res.data[i].one_to_three;
                        o.three_to_five = res.data[i].three_to_five;
                        o.more_than_five = res.data[i].more_than_five;
                        o.departTotal = res.data[i].departTotal;
                        this.tableData.push(o);
                    }
                }
                this.getCharts(this.tableData)
                this.tableData.push(temp);
            }).catch(err => {
                console.log(err);
            });
        },

        getCharts(res) {
            var myChart = echarts.init(document.getElementById('siLing'), 'dark');
            // 指定图表的配置项和数据
            var option = {
                grid: { left: 60, top: 60, bottom: 50, right: 130 },
                backgroundColor: '#3c3c3c',
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        var name = '';
                        switch (params.seriesName) {
                            case 'zero_to_one': name = '0-1年'; break;
                            case 'one_to_three': name = '1-3年'; break;
                            case 'three_to_five': name = '3-5年'; break;
                            case 'more_than_five': name = '5年及以上'; break;
                            default: name = '';
                        }
                        return '<div>' + params.name + '<br>' + params.marker + name + ': ' + params.value[params.seriesName] + '人</div>';
                    }
                },
                legend: {
                    x: 'right',
                    y: 'center',
                    orient: 'vertical',
                    align: "left",
                    formatter: function (name) {	// 添加
                        switch (name) {
                            case 'zero_to_one': return '0-1年';
                            case 'one_to_three': return '1-3年';
                            case 'three_to_five': return '3-5年';
                            case 'more_than_five': return '5年及以上';
                            default: return '计数人数(人)'
                        }
                    },
                },
                color: ['', '#589cdb', '#f47a26', '#a7a7a7', '#fcc402', '#505050'],
                // tooltip: {},
                dataset: {
                    // 这里指定了维度名的顺序，从而可以利用默认的维度到坐标轴的映射。
                    // 如果不指定 dimensions，也可以通过指定 series.encode 完成映射，参见后文。
                    dimensions: ['deptName', 'a', 'zero_to_one', 'one_to_three', 'three_to_five', 'more_than_five'],
                    source: res,
                },
                xAxis: {
                    type: 'category',
                    name: '部门',
                    nameLocation: 'center',
                    nameTextStyle: {
                        padding: [0, 1420, 0, 0],
                    }
                },
                yAxis: [{ type: 'value', name: '计数(人)' }],
                series: [
                    { type: 'bar' },
                    { type: 'bar' },
                    { type: 'bar' },
                    { type: 'bar' },
                    { type: 'bar' }
                ]
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },

        //导出
        downloadExcel() {
            this.$api.user.exportStaffWorkAgeReport().then((res) => {
                console.log(res)
                var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
                var url = URL.createObjectURL(blob)
                var exportLink = document.createElement('a')
                exportLink.setAttribute('download', '司龄透视分析表.xlsx')
                exportLink.href = url
                document.body.appendChild(exportLink)
                exportLink.click()
                document.body.removeChild(exportLink) //下载完成移除元素
                URL.revokeObjectURL(exportLink.href) //释放掉blob对象
                this.$message({
                    message: '导出成功!',
                    type: 'success',
                })
            }).catch((err) => {
                console.log(err);
            })
        },
    }
}
</script>
<style scoped lang="scss">
.age {
    padding: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .age-top {
        margin-bottom: 5px;

        .age-top-left {
            margin: 5px;
            height: 40px;
        }

        .age-top-right {
            position: relative;

            .age-top-btn {
                position: absolute;
                right: 8px;
                top: -35px;
                width: 90px;
            }
        }
    }

    /deep/.age-cen .el-table thead.is-group th,
    /deep/ .el-table tr:last-of-type,
    /deep/.el-table tr:last-of-type:hover>td {
        background-color: #404040;
        color: #fff;
    }
}

/deep/.el-table tr {
    background-color: #ededed;
}

/deep/ .el-table--border .el-table__cell {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}

/deep/.el-table--border {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

/deep/ .el-table .cell {
    white-space: pre-wrap; //表格换行
    padding: 0px;
}
</style>
